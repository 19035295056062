<script lang="tsx">
import type { ColorSwatch } from '@sim-api-types/components'
import { ProductVariationPropertyAttributeModel } from '@simploshop-models/custom/product-variation-property-attribute.model'
import { LazyNuxtImg } from '#components'
import { defineComponentCoreUiSelector } from './CoreUiSelector.vue'

export default function defineComponentCoreUiColorSwatchSelector(options?: Parameters<typeof defineComponentCoreUiSelector<ColorSwatch | ProductVariationPropertyAttributeModel | null>>[0]) {
    return defineComponentCoreUiSelector<any>({
        props: {
            ariaLabelKey: {
                default: '_core_simploshop.labels.color_selector',
            },
            valueGetter: {
                default: 'value',
            },
            // @ts-ignore
            labelGetter: {
                // @ts-ignore
                default: props => props?.values?.[0] instanceof ProductVariationPropertyAttributeModel ? (item: ProductVariationPropertyAttributeModel) => item.name : (item: ColorSwatch) => item.label,
            },
            // @ts-ignore
            useIndexAsValue: {
                // @ts-ignore
                default: () => item => item instanceof ProductVariationPropertyAttributeModel,
            },
        },
        slots: {
            checkbox: ({ item }) => {
                return (
                    <div class="sim-selector__background"
                        style={{
                            backgroundColor: item instanceof ProductVariationPropertyAttributeModel ? item.value ?? undefined : item?.value,
                        }}
                    >
                        {item?.imageUrl && (
                            <LazyNuxtImg
                                src={item.imageUrl}
                                alt=""
                                class="no-drag absolute inset-0 size-full"
                                width={30}
                                height={30}
                                loading="lazy"
                            />
                        )}
                    </div>
                )
            },
        },
        ...options,
    })
}

</script>

<style lang="scss" scoped>

</style>
